import { create } from 'zustand';
import { INIT_MAP_VIEW_STATE } from '../util/data-factory';

const useMapViewStore = create(set => ({
  mainMapViewState: {
    ...INIT_MAP_VIEW_STATE,
  },
  listMapViewState: {
    ...INIT_MAP_VIEW_STATE,
    zoom: 12,
  },
  setMainMapViewState: mapViewState => set({ mainMapViewState: mapViewState }),
  setListMapViewState: mapViewState => set({ listMapViewState: mapViewState }),
}));

export default useMapViewStore;
